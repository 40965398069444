<template>
  <div>
    <CCard>
      <CCardHeader color="info">
        <span style="color: white"> Datos de compra </span></CCardHeader
      >
      <CCardBody>
        <CRow>
          <CCol xs="12" sm="12" md="4" lg="4" xl="4">
            <label for="">Proveedor</label>
            <v-select
              :options="provider"
              placeholder="Seleccione proveedor"
              v-model="head.provider"
            />
          </CCol>
          <CCol xs="12" sm="12" md="2" lg="2" xl="2">
            <CInput label="Fecha" type="date" v-model="head.date" />
          </CCol>
          <CCol xs="12" sm="12" md="2" lg="2" xl="2">
            <SelectDev
              label="Tipo Documento"
              :options="typeDocument"
              placeholder="Tipo documeto"
              :value.sync="head.typeDocument"
            >
            </SelectDev>
          </CCol>
          <CCol xs="12" sm="12" md="2" lg="2" xl="2">
            <CInput
              label="Num. documento"
              placeholder="Num. documento"
              v-model="head.document"
            />
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
    <CCard>
      <CCardHeader color="info">
        <span style="color: white"> Detalle de compra </span></CCardHeader
      >
      <CCardBody>
        <CRow>
          <CCol xs="12" sm="12" md="4" lg="4" xl="4">
            <label for="">Insumo / Máquina</label>
            <v-select
              :options="all"
              placeholder="Seleccione una opción"
              v-model="itemDetail.product"
            />
          </CCol>
          <CCol xs="12" sm="12" md="1" lg="1" xl="1">
            <CInput
              v-on:keypress="isNumber($event)"
              label="Cantidad"
              placeholder="Cantidad"
              v-model="itemDetail.quantity"
            />
          </CCol>
          <CCol xs="12" sm="12" md="2" lg="2" xl="2">
            <label for="">Unidad</label>
            <v-select
              :options="units"
              placeholder="Seleccione una unidad"
              v-model="itemDetail.unit"
            />
          </CCol>
          <CCol xs="12" sm="12" md="1" lg="1" xl="1">
            <CInput
              v-on:keypress="isNumber($event)"
              label="Precio unitario"
              placeholder="Precio unitario"
              v-model="itemDetail.price"
            />
          </CCol>
          <CCol xs="12" sm="12" md="1" lg="1" xl="1">
            <CInput
              v-on:keypress="isNumber($event)"
              label="Subtotal"
              disabled
              placeholder="Subtotal"
              v-model="cpSubTotal"
            />
          </CCol>
          <CCol xs="12" sm="12" md="1" lg="1" xl="1">
            <label for="">&nbsp;</label>
            <br />
            <CButton color="info" @click="mtdAddItem" :disabled="cpButtonAdd"
              ><i class="fas fa-plus"></i>
            </CButton>
          </CCol>
          <CCol
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            v-if="detail.length > 0"
          >
            <div class="position-relative table-responsive">
              <table
                class="
                  table table-striped table-fixed table-hover table-bordered
                "
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Insumo / Máquina</th>
                    <th>Cantidad/Unidad</th>
                    <th>Precio</th>
                    <th>Subtotal</th>
                    <th>Acción</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(item, index) in detail" :key="index">
                    <td width="5">{{ index + 1 }}</td>
                    <td>{{ item.product.label }}</td>
                    <td>{{ item.quantity }} {{ item.unit.label }}</td>
                    <td>{{ parseFloat(item.price).toFixed(2) }}</td>
                    <td>{{ item.subtotal }}</td>
                    <td width="5" align="center">
                      <CButton
                        color="danger"
                        variant="outline"
                        size="sm"
                        v-c-tooltip="{ content: 'Eliminar', placement: 'top' }"
                        @click="mtdDelItem(index)"
                      >
                        <i class="fas fa-trash"></i>
                      </CButton>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </CCol>
          <CCol
            xs="12"
            sm="12"
            md="12"
            lg="12"
            xl="12"
            v-if="detail.length > 0"
          >
            <div class="d-flex justify-content-center">
              <CButton
                color="danger"
                variant="outline"
                size="sm"
                v-c-tooltip="{ content: 'Vaciar carito', placement: 'top' }"
                @click="mtdDelAll"
                ><i class="fas fa-trash"></i> Vaciar Carrito
              </CButton>
              <CButton
                class="ml-1"
                color="success"
                variant="outline"
                size="sm"
                v-c-tooltip="{ content: 'Terminar compra', placement: 'top' }"
                @click="mtdCommitPurchase"
                ><i class="fas fa-save"></i> Guardar compra
              </CButton>
            </div>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { bus } from "../../main";
import moment from "moment";

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import SelectDev from "../../components/shared/inputs/cSelect.vue";
export default {
  name: "v-proccess-purchase",
  components: { vSelect, SelectDev },
  data() {
    return {
      provider: [],
      all: [],
      units: [],
      typeDocument: [
        { value: 1, label: "Sin documento" },
        { value: 2, label: "Boleta" },
        { value: 3, label: "Factura" },
        { value: 4, label: "Otro documento" },
      ],
      head: {
        provider: [],
        typeDocument: [],
        date: null,
        document: "",
      },
      detail: [],
      itemDetail: {
        product: [],
        unit: [],
        quantity: "",
        price: "",
        subtotal: "",
      },
    };
  },
  computed: {
    cpSubTotal: function () {
      if (this.itemDetail.quantity > 0 && this.itemDetail.price > 0) {
        this.itemDetail.subtotal = parseFloat(
          this.itemDetail.quantity * this.itemDetail.price
        ).toFixed(2);
      } else {
        this.itemDetail.subtotal = parseFloat(0.0).toFixed(2);
      }
      return this.itemDetail.subtotal;
    },
    cpButtonAdd: function () {
      if (this.itemDetail.subtotal > 0) return false;
      return true;
    },
  },
  created() {
    this.mtdGetData();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtdGetData: function () {
      this.get({
        url: this.$store.getters.get__url + "/proccess/purchase",
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          // console.log(response);
          moment.locale("es");
          this.head.date =
            moment().format("L").substr(6, 4) +
            "-" +
            moment().format("L").substr(3, 2) +
            "-" +
            moment().format("L").substr(0, 2);
          this.provider = response.provider;
          this.all = response.all;
          this.units = response.unit;
        })
        .catch((errors) => {});
    },
    mtdAddItem: function () {
      this.detail.push(this.itemDetail);
      this.itemDetail = {
        product: [],
        unit: [],
        quantity: "",
        price: "",
        subtotal: "",
      };
    },
    mtdDelItem: function (pos) {
      let temp = [];
      this.detail.forEach((element, index) => {
        if (index != pos) {
          temp.push(element);
        }
      });
      this.detail = temp;
    },
    mtdDelAll: function () {
      this.detail = [];
    },
    mtdCommitPurchase: function () {
      if (this.head.document.length > 0) {
        if (this.detail.length > 0) {
          this.post({
            url: this.$store.getters.get__url + "/proccess/purchase/store",
            token: this.$store.getters.get__token,
            params: {
              head: this.head,
              detail: this.detail,
            },
          })
            .then((response) => {
              // console.log(response);
              if (response.state == 0) {
                this.head = {
                  provider: [],
                  typeDocument: [],
                  date: null,
                  document: "",
                };
                this.detail = [];
                this.itemDetail = {
                  product: [],
                  unit: [],
                  quantity: "",
                  price: "",
                  subtotal: "",
                };
                moment.locale("es");
                this.head.date =
                  moment().format("L").substr(6, 4) +
                  "-" +
                  moment().format("L").substr(3, 2) +
                  "-" +
                  moment().format("L").substr(0, 2);
                bus.$emit("alert", {
                  color: "success",
                  message: "COMPRA GUARDADA CORRECTAMENTE!!",
                });
              } else {
                bus.$emit("alert", {
                  color: "danger",
                  message: response.message,
                });
              }
            })
            .catch((errors) => {});
        } else {
          bus.$emit("alert", {
            color: "warning",
            message: "CARRITO VACÍO",
          });
        }
      } else {
        bus.$emit("alert", {
          color: "warning",
          message: "COMPLETE TODOS LOS CAMPOS DE LA CABECERA",
        });
      }
    },

    /** helpers */
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>